export const carouselContent= [
        {title:"\t“喷材制造”是把计算技术、数字制造、先进材料完美结合的精密制造，是未来先进制造的重要组成部分" ,backgroundImg:require('../../public/img/indexCarousel4.jpg')},
        {title:"\t高量科技致力于成为全球领先的全自主可控平台级高精密喷材制造装备提供商",backgroundImg:require('../../public/img/indexCarousel1.jpg')},
        {title:"以“软件定义、数据驱动”全新范式提升中国制造能级，助力国家实体经济发展\r\n" ,backgroundImg:require('../../public/img/indexCarousel2.jpg')}]


export const techType = [
    {icon: "Search", data: "自主可控的AI增强RIP工业软件"},
    {icon: "HelpFilled", data: "喷制专用核心处理器"},
    {icon: "ColdDrink", data: "智喷高性能计算机"},
    {icon: "IceCreamRound", data: "基于分布式互联的工业云智能"}]

export const third1 = {
    title: "智能云\n",
    backgroundImg:require('../../public/img/index1.png'),
    mainTitle: "高量工业智能云平台",
    mainContent: "智能喷制装备本身的云组网能力，可灵活、可扩展构建企业云平台，支持企业数据资产随着生产时间的积累不断增值。同时，可整体构建工业超级云平台。"
}

export const third2 = {
    title: "深瞳智源",
    img: "https://img.ixintu.com/download/jpg/202003/c115d96d4fd3c5aef856d3ed17457202_800_399.jpg%21con",
    mainTitle: "深瞳智源视觉计算平台",
    mainContent: "深瞳智源视觉计算平台具备精准的大数据采集与识别归纳能力，可实现跨场景、多目标的智能识别、多类属性提取与数据分析，" +
        "广泛应用于城市管理、智慧金融、商业零售等场景。"
}
