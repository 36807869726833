<template>
  <div class="box-parent" style="padding-bottom:40px;margin-top: -20px">
    <p>{{product1.title}}</p>
    <div class="main">
      <div >
        <div class="box">
          <div class="item" >
            <img :src="product1.backgroundImg1" >
            <div style="flex:1"><el-descriptions class="title"  :column="1" border  >
              <el-descriptions-item
                  v-for="(item,index) in product1.items1" :key="index"
                  :label="item.title"
                  label-align="right"
                  align="center"
                  label-class-name="my-label"
                  class="my-content"
              >
                {{item.content}}
              </el-descriptions-item>
            </el-descriptions></div>

          </div>

        </div>
      </div>

  </div>



  </div>

</template>

<script>
export default {
  name: "product1",
  props:{
    product1:{
      default:{
        title:"平台级可扩展型高精密喷制装备",
        items1:[

          {title:"核心软件",content:"自研数据模型融合型工业软件"},
        ],
        backgroundImg1:require('../../../public/img/product1.png'),
      }

    }
  }
}
</script>

<style scoped>
.main{
  height: 100%;
  width: 70vw;
  margin: 0 auto;
  /*text-align: center;*/
  box-shadow: 0 0 15px 2px #58a6f8;
}
.main div{
  flex-wrap:wrap;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}
.item{
  display: flex;
}
p{
  padding-top: 70px;
  font-size: 35px;
}
.title{
  margin-left: 5px;
  margin-top: 2%;
}
.my-content {
  background: var(--el-color-danger-light-9);
  text-align: center;

}
/deep/.el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-right{
  text-align: center;
}
img{
  border: 3px #bfc9d5 solid;
  margin: 0 auto;
  width: 50%;
  flex: 1;
}

.my-content {
  background: var(--el-color-danger-light-9);
  text-align: center;
}
/deep/.el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-right{
  text-align: center;
}
@media (max-width: 1000px) {
.item{

  display: block;
}
  .box-parent p{
    font-size: 20px;
  }
  .box-parent .item img{
    width: 100%;
  }
  .box-parent .item div{
    width: 100%;
  }
}
</style>
