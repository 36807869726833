<template>

  <div>
    <div>
      <el-menu
          :default-active="header.activeIndex"
          class="el-menu"
          mode="horizontal"
          @select="handleSelect"
          background-color="#fff"
          text-color="#000"
          active-text-color="#fff"
          menu-trigger="hover"
          style="border: none;"
          router="true"
      >

        <el-menu-item style="padding: 0;">
          <el-link :underline="false">
            <img alt="Vue logo" :src="header.icon" style="height: 55px;margin-left: 20px"/>
          </el-link>
        </el-menu-item>


        <div style="display: flex;right: 0;position: absolute;padding-right: 7vw;">
          <div v-for="(item,index) in header.NavMenu" @click="fun">
            <el-menu-item :index="item.index" v-if="!item.hasSubMenu" >
              <el-link :underline="true" type="info"
                       style="color: #000" >
                {{ item.title }}
              </el-link>
            </el-menu-item>

            <el-sub-menu :key="index" :index="index" v-else  style="border: none">

              <template #title>{{ item.title }}</template>

              <div class="sub-item" v-if="item.hasSubMenu" style="border: none">
                <div class="img"><img :src="item.img"></div>

                <div class="sub-item-content" >
                  <div v-for="(subItem,subindex) in item.subMenu" :key="subindex">
                    <span><i :class="subItem.icon"></i></span>
                    {{ subItem.title }}
                    <p>
                      <el-link :underline="true" type="info" v-for="(item,index) in subItem.item" :key="index"
                               style="color: #cad2d2">
                        {{ item }}
                      </el-link>
                    </p>
                  </div>
                </div>

              </div>

            </el-sub-menu>
          </div>

        </div>
      </el-menu>

    </div>

  </div>
</template>

<script>
import {enableMove, stopMove} from "@/utils/utils";

export default {
  name: "header",
  props:{
    header:{
      default:{

      }
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key==='/index'){
        stopMove()
      }else{
        enableMove()
      }
      console.log(key, keyPath);
    },
    fun(){
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>
.sub-item {
  line-height: 30px;
  display: flex;
  width: 80vw;
  color: white;
  padding: 30px;
  border: none;
  background-color: #c9d3e5;
  align-content: center;
}
.img {
  flex: 0.3;
  border-right: #63dbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid;
}
 /deep/.el-popper.is-light {
  :deep(.is-light  .el-popper) {
  border: none var(--el-border-color-light);
  resize: none;
  cursor: default;
}
}


.img img {
  width: 60%;

}

.sub-item-content {
  flex: 0.7;
  font-size: 20px;
  padding: 30px;
  display: flex;
}

.sub-item-content div {
  flex: 0.3;
}

.sub-item-content div span {
  font-size: 35px;
  color: #3fafaf;
}

.asub-item-content div p el-link {
  font-size: 15px;

}
</style>
