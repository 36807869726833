<template>
  <div class="main">
    <span>{{companyNews.title}}</span>
    <div>
<!--      <h1>Page {{ currentPage }}</h1>-->
      <div class="news" v-for="(item,index) in companyNews.news" :key="index">
<!--          <div class="img" :style="{backgroundImage: 'url(' + item.backgroundImg + ')' }">-->
<!--          </div>-->
          <div class="content">
            <div class="title">{{item.title}}</div>
            <div class="date">发布时间： {{item.publishDate}}</div>
          </div>
      </div>
      <!-- 显示当前页的内容 -->
    </div>
    <div class="page">
      <el-pagination style=" justify-content: center;" background align="center" layout="prev, pager, next" :total="1" />

    </div>



  </div>
</template>

<script>
export default {
  name: "companyNews",
  props:{
    companyNews:{
      default:{

      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize); // 计算总页数
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page; // 更新当前页
      // 执行其他逻辑，例如获取当前页的数据
    },
  },
}
</script>

<style scoped>
.main{
  padding-top: 80px;
  width: 80%;
  height: 60%;
  margin: 0 auto;
}
.main span{
  font-size:40px;
}
.main ul{
  display: flex;
}
.main ul li{
  list-style-type:none;
  margin: 0 auto;
}
.main ul li button{
  border: 0;
}
.main ul li button:focus{
  color: #e5e9f2;
  background-color: deepskyblue;
}
.news{
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 5px #e1e2e5;
}
.img{
  flex: 0.2;
  background-color: #42b983;
  margin-right: 30px;
  margin-left: 30px;
  background-size: cover;
  object-fit: cover;
}
.content{
  flex: 0.6;
  width: 80vw;
  text-align: left;
  line-height: 2rem;
  padding-top: 30px;
  padding-left:20%;
  padding-right: 30px;
}
.title{
  font-size:18px;
}
.date{
  color: #b5b7ba;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-bottom: 30px;
}
.page{
  margin-top: 30px;
  padding-bottom: 50px;

}
@media (max-width: 1000px) {
  .title{
    font-size:10px;
    width: 60vw;
    padding-left: 10px;
  }
  .main span{
    font-size:30px;
  }
  .date{
    font-size: 6px;
    padding-left: 10px;
  }
  .img{
    display: none;
  }

}
</style>
