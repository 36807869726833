<template>
  <div class="all">
    <div class="line"><el-divider border-style="double" /></div>
    <div class="main">
      <p>{{technology2.title1}}</p>
      <img :src="technology2.backgroundImg">
    </div>
  </div>


</template>

<script>
import ShakeBox from "@/components/components/shake-box";
export default {
  name: "technology2",
  components: {ShakeBox},
  props:{
    technology2:{
      default:{
      }
    }
  }
}
</script>

<style scoped>

.main{
  margin: 0 auto;
  text-align: center;
}
p{
  font-size: 35px;
}

img{
  border: 2px #b1c4de solid;
  width:70vw;
  height: 65vh;
  box-shadow: 0 0 10px 1px skyblue;
}

@media (max-width: 768px) {
  p{
    font-size: 20px;
  }
}
</style>
