<template>
<div class="main" :style="{backgroundImage: 'url(' + joinIndex.backgroundImg + ')' }">
    <p v-for="(item,index) in joinIndex.titles" :key="index">{{item}}</p>
</div>
</template>

<script>
export default {
  name: "joinIndex",

  props:{
    joinIndex:{
      default:{

      }
    }
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  padding-top: 10%;

  background-size: cover;
  padding-bottom: 20%;
}
.main p{

  text-align: left;
  padding-left: 10%;
  margin-top: 20px;
  color: #efeeee;
  padding-top:20px;
  font-size: 25px;
}
@media (max-width: 1000px) {

  .main p{
    font-size: 12px;
  }
}
</style>
