<template>
  <div :style="'background-color:'+backgroundColor" class="main-window">
    <div class="title-desc">{{ title }}</div>
    <div class="flex nav-box">
      <span
          class="nav-box-item"
          :class="tabIndex === index ? 'active' : ''"
          @click="changeTab(index)"
          v-for="(item, index) in content"
          :key="index"
      >{{ item.dataTitle }}</span
      >
    </div>

    <el-carousel
        direction="horizontal"
        ref="carousel"
        @setActiveItem="setActiveItem"
        :autoplay="false"
        @change="changeItem"
        :interval="5000"
    >
      <el-carousel-item v-for="item in content" :key="item">
        <div class="el-carousel-item">
          <div class="left">
            <div
                :class="{ 'enlarged': isHover }"
                @mouseover="isHover = true"
                @mouseleave="isHover = false"
                class="image-container"
            >
              <img :src="item.imgs">
            </div>
          </div>

          <div class="content right">
            <div>{{ item.dataTitle }}</div>
            <div>{{ item.dataContent }}</div>
            <div>
              <el-link type="danger" v-for="(link,index) in item.plan" :key="index" :underline="false">{{
                  link
                }}
              </el-link>
            </div>
          </div>

        </div>
      </el-carousel-item>
    </el-carousel>
  </div>

</template>

<script>
export default {
  name: "five",
  data() {
    return {
      tabIndex: 0,
      isHover: false
    }
  },
  props: {
    backgroundColor: {
      default: "#e6ebf3"
    },
    title: {
      default: "行业解决方案"
    },
    content: {
      default: [
        {
          imgs: "https://img1.baidu.com/it/u=4235415921,3657661079&fm=253&fmt=auto&app=120&f=JPEG?w=799&h=484",
          dataTitle: "城市管理",
          dataContent: "1231213212313131",
          plan: ["plan1", "plan2"]
        },
        {
          imgs: "https://img.pptjia.com/image/20200922/fb852c659271a76a07c01bb84e643bce.JPG",
          dataTitle: "商业零售",
          dataContent: "1231213212313131",
          plan: ["plan1", "plan2"]
        },
        {
          imgs: "https://pic.vjshi.com/2020-10-12/3ee08cc3e32426ce4928d25859aa6d5d/online/4612bc9e065a13a82f2fd64bb904614e.jpg?x-oss-process=style/resize_w_720",
          dataTitle: "智慧金融",
          dataContent: "1231213212313131",
          plan: ["plan1", "plan2"]
        },
        {
          imgs: "https://img1.baidu.com/it/u=4235415921,3657661079&fm=253&fmt=auto&app=120&f=JPEG?w=799&h=484",
          dataTitle: "轨交运维",
          dataContent: "1231213212313131",
          plan: ["plan1", "plan2"]
        },
        {
          imgs: "https://pic.vjshi.com/2020-10-12/3ee08cc3e32426ce4928d25859aa6d5d/online/4612bc9e065a13a82f2fd64bb904614e.jpg?x-oss-process=style/resize_w_720",
          dataTitle: "体育健康",
          dataContent: "1231213212313131",
          plan: ["plan1", "plan2"]
        },
      ],
    }
  },
  methods: {
    changeItem(e) {
      this.tabIndex = e;
    },
    changeTab(index) {
      if (this.tabIndex === index) {
        return;
      }
      this.tabIndex = index;
      this.setActiveItem(index);
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
  }
}
</script>

<style scoped>
.main-window {
  padding: 50px;
}

.title-desc {
  font-size: 30px;
}

.el-carousel__item h3 {
  color: #e6ebf3;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

/deep/ .el-carousel__container {
  height: 50vh;
}

.el-carousel-item {
  display: flex;
  width: 85%;
  height: 85%;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
}

.el-carousel-item .right, .left {
  flex: 1

}

.nav-box {
  width: 100%;
  line-height: 3em;
  border-bottom: 1px solid #d3dce6;
}

.nav-box-item {
  padding: 0 0.25rem /* 20/80 */;
  transition: all 0.6s linear;
  cursor: pointer;
  padding: 40px;
}


.el-carousel__item h3 {
  color: #e6ebf3;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.image-container, .image-container img {
  width: 100%;
  height: 100%;
}

.content {
  border: #63dbdb;
  background-color: #e0edf1;
  box-shadow: 0 0 30px #b9e3ec;
}
</style>
