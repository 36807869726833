<template>
  <!--2.-->
  <div class="list-box">

    <div class="title-desc">{{ title }}</div>

    <div class="describe-box">{{ describe }}</div>

    <div class="box-container">

      <shake-box v-for="(item,index) in type" :item="item"/>

    </div>
  </div>
</template>

<script>
import shakeBox from "@/components/components/shake-box";

export default {
  name: "second",
  components: {
    shakeBox
  },
  data() {
    return {}
  },
  props: {
    type: [],
    title: {
      default: "核心技术"
    },
    describe: {
      default: "高性能计算+人工智能+高精密运控系统"
    }
  }
}
</script>

<style scoped>

.title-desc {
  font-size: 30px;
  /*margin-top: 70px;*/
}

.describe-box {
  margin-top: 20px;
  margin-bottom: 30px;
  color: #909092;
}

.list-box {
  padding: 50px;
  min-height: 400px;
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
