<template>
  <div>
    <div class="el-carousel__container">
      <!--1.走马灯-->
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item,index) in carouselContent" :key="index">
          <div :style="{backgroundImage: 'url(' + item.backgroundImg + ')' }">
            <div class="title">{{ item.title }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isHovered: false, // 是否悬停
    };
  },
  props: {
    carouselContent: {
      default: {
        title: "用最先进的“AI+HPC”技术塑造高端喷印装备\r\n" +
            "支撑企业智能化升级\n", backgroundImg: require('../../../public/img/indexCarousel4.jpg')
      }
    },

  },
  methods: {
    hoverCard(isHovered) {
      this.isHovered = isHovered;
    },
  },
}
</script>

<style scoped>
/*********1********************/

/deep/ .el-carousel__container {
  height: 100vh;

}

/deep/ .el-carousel__container div {
  height: 95vh;
  background-size: cover;
  color: white;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 20px;
  text-align: center;
  font-weight: lighter;
  vertical-align: center;
  margin: 0 auto;
}

.title {
  padding-top: 40vh;
  width: 69vw;
}

@media (max-width: 768px) {
  /deep/ .el-carousel__container div {
    height: 95vh;
    background-size: cover;
    color: white;
    font-size: 25pt;
    line-height: 34pt;
    letter-spacing: 10pt;
    text-align: center;
    vertical-align: center;
    margin: 0 auto;
  }
}
</style>
