export const footer={
    footerContent: [
        {footerLinkTitle: "关于高量", footerlink: [{title:"关于我们",link:'company'}, {title:"高量荣誉",link:'company'}]},
        {footerLinkTitle: "产品展示", footerlink: [{title:"高量产品",link:'product'}]},
        {footerLinkTitle: "快速导航", footerlink: [{title:"公司简介",link:'company'},{title:"高量产品",link:'product'},
                // {title:"新闻中心",link:'news'},
                // {title:"联系方式",link:'contact'}
                ]},
    ],
    // footerContent: [
    //   {footerLinkTitle: "首页"},
    //   {footerLinkTitle: "高量技术"},
    //   {footerLinkTitle: "高量产品"},
    //   {footerLinkTitle: "关于高量"},
    //   {footerLinkTitle: "新闻中心"},
    //   {footerLinkTitle: "加入高量"},
    // ],
    QRcode: "https://img.xjishu.com/img/zl/2018/6/30/1241359458913.gif",
    address: "©2023 高量工业智能科技（无锡）有限公司\n",
    BeiAn: "苏ICP备2023021655号"
}
