export const aboutIndex={
    icon: "",
    text_align:'text-align:left',
    backgroundImg:require('../../public/img/companyIntroduceImg.jpg'),
    title:["关于我们"],
    describe:["高量科技，积极响应中国经济高质量发展的号召，以提升我国高端装备自主可控能力、推动智能制造的纵深发展为使命。高量科技目前由高量工业智能科技（无锡）有限公司、高量数智先进技术研究（佛山）有限公司、高量高精密喷制先进技术研发中心三部分组成。无锡公司立足于无锡及长三角地区在人工智能、高性能计算、集成电路等领域的产业及人才优势，专注于喷材制造装备核心系统的基础软硬件和核心算法研发。佛山公司立足于珠三角地区强大的制造业基础，负责公司喷材制造整机装备的研发和推广。高量科技基于国际领先的自主研发核心技术，大幅提升喷材制造装备的生产效率从而满足大规模工业生产的需求，同时赋予装备生命与智能及深度定制能力，为PCB/SMT、柔性喷印、光伏电池、新型包装等核心产业转型升级提供核心动能。"]
}

export const teamIntroduce={
    title:"团队介绍",
    imgSrc:require('../../public/img/teamIntroduce.jpg'),
    content:"公司创始团队在人工智能、大数据、云计算、物联网、自主可控计算机系统、领域专用芯片架构设计、机器人运动控制方面有长期的积累，专长于以软硬件垂直优化解决细分领域的瓶颈问题。核心研发团队有来自美国宾夕法尼亚大学、中科院计算技术研究所、复旦大学、美国西弗吉尼亚大学、美国斯蒂芬斯理工学院等国内外名校的博士/博士后，以及来自自主可控CPU及基础软件核心团队的技术骨干，确保了技术和产品的领先性。"
}

export const companyHonor={
    title:"高量荣誉",
    backgroundImg:require('../../public/img/companyHonorsBacImg.jpg'),
    activities: [{
        content: '高量工业智能科技（无锡）有限公司成立，入驻江南大学国家大学科技园',
        timestamp: '2022年6月',
        color: '#2f6ede'
    }, {
        content: '公司获江南大学校友创新创业大赛粤港澳大湾区分站赛特等奖（第一名）',
        timestamp: '2022年7月',
        color: '#74cfff'
    }, {
        content: '公司获中国无锡“太湖杯”国际精英创新创业大赛总决赛三等奖',
        timestamp: '2022年10月',
        color: '#2f6ede'
    }, {
        content: '公司获江南大学校友创新创业大赛总决赛一等奖（第一名）',
        timestamp: '2022年11月',
        color: '#74cfff'
    },{
        content: '公司获批无锡市滨湖区“滨湖之光”创业领军人才',
        timestamp: '2022年6月',
        color: '#2f6ede'
    },{
        content: '公司获创投机构千万级天使轮投资',
        timestamp: '2022年12月',
        color: '#74cfff'
    },{
        content: '公司获江南大学国家大学科技园“高成长性企业”称号',
        timestamp: '2023年3月',
        color: '#2f6ede'
    }]
}


