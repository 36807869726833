<template>
  <div class="main" >
   <p class="title">{{contact.title}}</p>

    <div class="address" >

      <el-icon style="font-size: 40px;color: #3b8cd4;padding-left: 20px"><component :is="contact.icon"></component>
      </el-icon>
      <span class="address" style="">{{contact.address}}</span>
    </div>
    <div class="contactWay" >
      <div v-for="(item,index) in contact.contactWay" :key="index" >
        <el-icon style="font-size: 30px;color: #3b8cd4;">
          <component :is="item.icon"></component>
        </el-icon>
          <div style="display: flex">
            <span class="span1">{{item.title}}</span>
            <span class="span2">{{item.way}}</span>
          </div>
      </div>
    </div>


<!--    <div class="QRcode" style="display: flex">-->
<!--      <div v-for="(item,index) in QRcode" :key="index">-->
<!--        <p>{{item.type}}</p>-->
<!--        <div>{{item.QRcode}}</div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "contact",
  props:{
    contact:{
      default:{

      }
    }
  }
}
</script>

<style scoped>
.main{
  height: 100%;
  background-color: rgb(245,247,248) ;
}

.title{
  padding-top: 40px;
  font-size: 40px;
  color: #000;
}
.address{
  display: flex;
  width: 600px;
  margin: 0 auto;
  background-size: cover;
  text-align: left;
  margin-bottom: 20px;
}
.address span{
  padding-top: 10px;
}
img{
  width: 50%;
  height: 40%;
  margin-bottom:60px;
}
.contactWay{
  display: flex;
  width: 60vw;
  margin: 0 auto;
}
.contactWay div{
  margin: 0 auto;
  display: flex;

}
.contactWay span{
  padding-bottom: 50px;
}
.span1{
  padding-top: 5px;
}
.span2{
  padding-top: 8px;
}
.QRcode{
  width: 60%;
  height: 100%;
  margin: 0 auto;
}
.QRcode div{
  height:60%;
  width: 100%;
  margin: 0 auto;
}
.QRcode div div{
  width: 50%;
  height: 50%;
  margin: 0 auto;
  background-color: blue;
}
@media (max-width: 1000px) {
  .title{
    font-size: 30px;
  }
  .address{
   font-size: 10px;
  }
  .address{
    display: block;
    text-align: center;
    width: 100%;
  }
  .contactWay span{
    font-size: 10px;
  }
  .span2{
    padding-top: 7px;
  }
}
</style>
