<template>
  <div class="main">
    <p>{{teamIntroduce.title}}</p>
    <div>
      <div class="left">
        <img :src="teamIntroduce.imgSrc">
      </div>
      <div class="right">{{teamIntroduce.content}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamIntroduce",
 props:{
    teamIntroduce:{
      default:{

      }
    }
 }
}
</script>

<style scoped>

.main p{
  color: #000;
  font-size: 40px;
  padding-top: 60px;
}
.main div{
  display: flex;
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 70px;
}
.left{
  flex: 0.3;
  width: 40vw;
  height:30vh ;
}

.right{
  text-indent:2em;
  flex: 0.6;
  background-color: #f8f8f8;
  text-align: left;
  padding: 50px;
  line-height: 45px;
  color: #000;
  font-size: 1.3rem;
  box-shadow:20px 10px 10px 0px rgba(158, 149, 149, 0.4);

}
@media (max-width: 1000px) {

  .main div{
    display: block;
    padding-right: 20px;
  }
  .left{
    margin-bottom: 5px;
  }
  img{
    width: 70vw;
    height: 40vh;
  }
  .main p{;
    font-size: 30px;
  }
  .right{
    width: 80vw;
    padding: 0;
    padding-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
    line-height: 20px;
    font-size: 10px;
   box-shadow: none;
  }
}
</style>
