<template>
    <div >

      <index-bac-img style="text-align: center;text-indent:1em" :viewData="aboutIndex"></index-bac-img>
      <team-introduce :teamIntroduce="teamIntroduce"></team-introduce>
      <company-honors :companyHonor="companyHonor"></company-honors>
      <el-backtop :right="100" :bottom="100" style="width: 100px;height: 100px" @click="fun">
        <template #default>回到顶部</template>
      </el-backtop>
    </div>
</template>

<script>
import TeamIntroduce from "@/components/company/teamIntroduce";

import CompanyHonors from "@/components/company/companyHonors";
import News from "@/components/news/companyNews";
import {aboutIndex,teamIntroduce,companyHonor}from "@/data/aboutCompany"
import IndexBacImg from "@/components/components/indexBacImg";
import {enableMove, scrollUtils, stopMove} from "@/utils/utils";

export default {
  name: "company",
  components: {IndexBacImg, News, CompanyHonors, TeamIntroduce},
  metaInfo:{
    title:'关于高量',
    meta:[
      {
        name:'关键词：关于高量',
        content:'关于高量啊'
      }
    ]
  },
  data(){
    return {
      aboutIndex:aboutIndex,
      teamIntroduce:teamIntroduce,
      companyHonor:companyHonor,
      domList: [],
      nowIndex: 0,
      scrollStatus:{
        isScrolling: false
      }
    }
  },
 methods: {
   scrollInit(e) {
     stopMove()
     this.nowIndex = scrollUtils(e, this.nowIndex, this.domList,this.scrollStatus)
   },
   fun(){
     this.nowIndex=0
   }
 },
}
</script>

<style scoped>

</style>
