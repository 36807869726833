export const newsIndex={
    title:"新闻中心",
    subTitle:"NEWS CENTER",
    backgroundImg:require('../../public/img/newIndex.jpg'),
}

export const companyNews={
    backgroundImg:require('../../public/img/newsImg.jpg'),
    title:"公司新闻",
    news:[
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"公司获江南大学国家大学科技园“高成长性企业”称号",
            publishDate:"2023年3月"
        },
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"公司获创投机构千万级天使轮投资",
            publishDate:"2022年12月"
        },
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"公司获江南大学校友创新创业大赛总决赛一等奖（第一名）",
            publishDate:"2022年11月"
        },
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"公司获中国无锡“太湖杯”国际精英创新创业大赛总决赛三等奖",
            publishDate:"2022年10月"
        },
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"公司获江南大学校友创新创业大赛粤港澳大湾区分站赛特等奖（第一名）",
            publishDate:"2022年7月"
        },
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"高量工业智能科技（无锡）有限公司成立，入驻江南大学国家大学科技园",
            publishDate:"2022年6月"
        },
        {
            backgroundImg:require('../../public/img/newsImg.jpg'),
            title:"公司获批无锡市滨湖区“滨湖之光”创业领军人才",
            publishDate:"2022年6月"
        },
    ]
}
