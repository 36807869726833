<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-left">
        <div class="box-card" v-for="(item,index) in footer.footerContent" :key="index">
          <div style="margin-bottom: 20px;font-size: 20px">
            <span>{{ item.footerLinkTitle }}</span>
          </div>

          <div v-for="(linkItem,index) in item.footerlink" :key="index" class="text item">
            <el-link type="info" :underline="false"  @click="change(linkItem)">
            {{ linkItem.title }}
            </el-link>
          </div>
        </div>
      </div>

      <div class="footer-right">
        <p>欢迎加入我们</p>
<!--        <img :src="QRcode">-->
      </div>
    </div>

    <div class="footer-bottom">
      <div>{{ footer.address }}</div>
      <div>{{ footer.BeiAn }}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "footer",
  props:{
    footer:{
      default:{

      }
    }
  },
methods:{
    change(a){
      this.$router.push({
        name: a.link
      });
    }
}
}
</script>

<style scoped>
.footer {
  background: #2c3e50;
  padding: 50px;
}

.footer-top {
  display: flex;
  border-bottom: 1px #7f7f7f solid;
  padding-bottom: 50px;
}

.footer-left {
  flex: 0.6;
  display: flex;
}

.footer-right {
  flex: 0.4;
  text-align: center;
  border-left: 1px #7f7f7f solid;
  color: white;
}

.footer-bottom {
  padding-top: 30px;
  color: #9397a1;
  font-size: 12px;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 2rem;
  flex: 0.2;
  color: #e5e9f2;
  border-radius: unset;
}

.footer-right img {
  width: 100px;
  height: 100px;
}
span{
  font-size: 18px;
  color: #dbd7d6;
}
</style>
