export function stopMove() {
    let m = function (e) {
        e.preventDefault();
    };
    document.body.style.overflow = 'hidden';
    document.addEventListener("touchmove", m, {passive: false});//禁止页面滑动
}


export function enableMove() {
    let m = function (e) {
        e.preventDefault();
    };
    document.body.style.overflow = ''; // 恢复默认的 overflow 样式
    document.removeEventListener("touchmove", m, {passive: false}); // 移除禁止页面滑动的监听事件
}


export function scrollUtils(e, nowIndex, domList, scrollStatus) {
    console.log( scrollStatus.isScrolling)
    if ( scrollStatus.isScrolling) return nowIndex; // 如果正在滚动中，则忽略后续的滚动事件
     scrollStatus.isScrolling = true; // 设置滚动标志为 true，表示正在滚动中
    let gap = e.deltaY;
    let dom;
    if (gap < 0) {
        console.log('up');
        if (nowIndex === 0) {
            // enableMove()
             scrollStatus.isScrolling = false
            return nowIndex;
        }

        if (nowIndex === 0) {
            return 0;
        }
        nowIndex = (nowIndex - 1) % domList.length;
        dom = domList[nowIndex];
    } else {
        if (nowIndex === domList.length - 1) {
             scrollStatus.isScrolling = false
            enableMove()
            // nowIndex = (nowIndex >= (domList.length - 1 + 2)) ? nowIndex : nowIndex + 1
            return nowIndex
        }

        dom = domList[nowIndex + 1];
        nowIndex = (nowIndex + 1) % domList.length;
        // if (nowIndex === domList.length - 1) {
        //     enableMove()
        // }
    }
    console.log(nowIndex)
    const rect = dom.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const top = rect.top + scrollTop - 57;
    window.scrollTo({
        top,
        behavior: 'smooth'
    });

    // 监听滚动结束事件，将滚动标志设置为 false
    window.addEventListener('scroll', () => {
         scrollStatus.isScrolling = false;
    }, {
        once: true
    });
    return nowIndex
}
