<template>
<div class="main">
  <news-index :newsIndex="newsIndex"></news-index>
  <company-news :companyNews="companyNews"></company-news>
</div>
</template>

<script>
import CompanyNews from "@/components/news/companyNews";
import NewsIndex from "@/components/news/newsIndex";
import {newsIndex,companyNews} from '@/data/news';

export default {
  name: "news",
  components: {NewsIndex, CompanyNews},
  metaInfo:{
    title:'新闻中心',
    meta:[
      {
        name:'关键词：新闻中心',
        content:'新闻中心啊'
      }
    ]
  },
  data(){
    return{
      newsIndex:newsIndex,
      companyNews:companyNews
    }
  }
}
</script>

<style scoped>
.main{
  background-color: #f7f8f8;
}
</style>
