<template>
  <div class="main">
    <div class="title-desc">{{ viewData.title }}</div>
    <div class="main-box" :style="imgPos==='left'?'':'flex-direction: row-reverse;'">
      <!--      <div class="left-box"  :style="{backgroundImage: 'url(' + viewData.backgroundImg + ')' }">-->
      <img :src="viewData.backgroundImg">
      <!--      </div>-->

      <div class="right-box">
        <div style="font-size: 25px;margin-top: 90px;line-height: 32.5px;letter-spacing: 5px;">{{
            viewData.mainTitle
          }}
        </div>
        <div style="margin-top: 20px;font-size: 18px;line-height: 27px;letter-spacing:3px;">{{
            viewData.mainContent
          }}
        </div>
<!--        <el-button type="primary" plain round style="margin-top: 20px">查看详情</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "third",
  data() {
    return {}
  },
  props: {
    viewData: {
      default: {
        title: "深瞳灵犀",
        img: "https://img.ixintu.com/download/jpg/202003/c115d96d4fd3c5aef856d3ed17457202_800_399.jpg%21con",
        mainTitle: "深瞳灵犀数据智能平台",
        mainContent: "深瞳灵犀数据智能平台，以数据为核心，融合智能数据接入、智能数据解析、智能数据治理、数据安全输出等能力，面向安防、" +
            "零售、金融、园区等行业软件开发 / 集成商提供一站式全融合数据智能服务。"
      }

    },
    imgPos: {
      default: "left"
    },
    backgroundColor: {
      default: "#8ebbe8"
    },
    fontColor: {
      default: "#dbcbcb"
    }
  }
}
</script>

<style scoped>
.main {
  background-image: linear-gradient(
    /*to right,*/ /*#5c81e0,*/ /*#ebedef,*/ /*rgb(0,123,255),*/ /*#e5e9ec*/ to right,
      #899ac9,
      #ebedef,
      rgb(114, 158, 206),
      #e5e9ec
  );
  height: 100%;
  height: 100%;
  padding: 20px;

}

.title-desc {
  padding-top: 50px;
  font-size: 30px;
}

.main-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

img {
  margin-top: 50px;
  flex: 0.5;
  width: 50%;
  height: 70%;
}

.right-box {
  margin-top: 10%;
  flex: 0.4;
  padding: 0 0 0 50px;
  text-align: left
}

@media (max-width: 768px) {
  img, .right-box {
    flex: 0 1 80%;
  }
}

</style>
