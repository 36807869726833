<template>
  <div id="main" @mousewheel="scrollInit">
    <div id="first">
      <first :carouselContent="carouselContent" style="height: 100vh"></first>
    </div>

    <div id="second" style="height: 100vh">
      <second :type="techType" style="height: 100vh"></second>
    </div>

    <div id="third" style="height: 100vh">
      <third img-pos="left" :view-data="third1" font-color="#fff" style="height: 100vh"></third>
    </div>

    <el-backtop :right="100" :bottom="100" style="width: 100px;height: 100px" @click="fun">
      <template #default>回到顶部</template>
    </el-backtop>
  </div>
</template>

<script>
import second from "@/components/index/second";
import first from "@/components/index/first";
import Third from "@/components/index/third";
import Five from "@/components/index/five";
import {carouselContent, techType, third1, third2} from '@/data/home';
import Product1 from "@/components/product/product1";
import Product3 from "@/components/product/product3";
import {enableMove, scrollUtils, stopMove} from "@/utils/utils";

export default {
  name: "index",
  components: {Product3, Product1, Five, Third, first, second},
  metaInfo: {
    title: '首页',
    meta: [
      {
        name: '关键词：首页',
        content: '首页啊'
      }
    ]
  },
  data() {
    return {
      carouselContent: carouselContent,
      third1: third1,
      third2: third2,
      techType: techType,
      domList: [],
      nowIndex: 0,
      scrollStatus:{
        isScrolling: false
      }
    }
  },
  methods: {
    scrollInit(e) {
      stopMove()
      this.nowIndex = scrollUtils(e, this.nowIndex, this.domList, this.scrollStatus)
    },
    fun(){
      this.nowIndex=0
    }
  },
  mounted() {
    stopMove()
    this.domList = Array.from(document.getElementById('main').children);
  },

}
</script>

<style scoped>
</style>
