<template>
  <div id="app">
  <RouterView></RouterView>

  </div>
</template>

<script>
import Header from "@/components/header";
import Index from "@/views/index";
import Footer from "@/components/footer";
export default {
  name: "App",
  components: { Footer, Index, Header}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
  padding: 0;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
