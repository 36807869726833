export const header={
    activeIndex: "1",
    icon:require('../../src/assets/icon.png'),
    NavMenu: [
        {
            title: "首页",
            index:"/index",
            hasSubMenu: false,
        },
        {
            title: "高量技术",
            index:"/technology",
            hasSubMenu: false,
        },
        // {
        //   title: "高量产品",
        //   hasSubMenu: true,
        //   subMenu: [{title: "产品展示", icon: "el-icon-platform-eleme", item: ["战略产品"]},
        //     {
        //     title: "核心产品",
        //     icon: "el-icon-platform-eleme",
        //     item: ["智喷"]
        //   }, {title: "核心产品", icon: "el-icon-platform-eleme", item: ["云边协同"]}],
        //   img: "https://ns-strategy.cdn.bcebos.com/ns-strategy/upload/fc_big_pic/part-00193-2644.jpg"
        // },
        // {
        //   title: "解决方案",
        //   hasSubMenu: true,
        //   subMenu: [{title: "城市管理", icon: "el-icon-user-solid", item: ["智能交通解决方案", "智慧社区/园区解决方案"]}, {
        //     title: "商业零售",
        //     icon: "el-icon-platform-eleme",
        //     item: ["商业智能解决方案", "智慧油站解决方案"]
        //   }, {title: "智慧金融", icon: "el-icon-platform-eleme", item: ["智慧金融解决方案"]}, {
        //     title: "轨交运维",
        //     icon: "el-icon-platform-eleme",
        //     item: ["轨交运维解决方案"]
        //   }, {title: "体育健康", icon: "el-icon-platform-eleme", item: ["体育健康解决方案"]}],
        //   img: "https://ns-strategy.cdn.bcebos.com/ns-strategy/upload/fc_big_pic/part-00193-2644.jpg"
        // },
        {
            title: "高量产品",
            index:"/product",
            hasSubMenu: false,
        },
        {
            title: "关于高量",
            index:"/company",
            hasSubMenu: false,
        },
        // {
        //     title: "新闻中心",
        //     index:"/news",
        //     hasSubMenu: false,
        // },
        {
            title: "加入高量",
            index: "/joinUs",
            hasSubMenu: false,
        },
    ]
}
