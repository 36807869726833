<template>
  <div class="all" style="padding-bottom:40px;margin-top: -20px;background-color: #eff0f3;">
    <p>{{ title2 }}</p>
    <div class="main">
      <div class="box">
        <div class="item">
          <div style="flex:1">
            <el-descriptions class="title" :column="1" border>
              <el-descriptions-item
                  v-for="(item,index) in product3.items2" :key="index"
                  :label="item.title"
                  label-align="right"
                  align="center"
                  label-class-name="my-label"
                  class="my-content"
              >
                {{ item.content }}
              </el-descriptions-item>
            </el-descriptions>
          </div>

          <img :src="product3.backgroundImg2">

        </div>

      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "product3",
  props:{
    product3:{
      default:{

      }
    }
  }
}
</script>

<style scoped>

.main {
  height: 100%;
  width: 70vw;
  margin: 0 auto;
  /*text-align: center;*/
  box-shadow: 0 0 15px 2px #58a6f8;
}

.main div {
  flex-wrap: wrap;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.item {
  display: flex;
}

.item div {
  padding-top: 3%;
}

p {
  padding-top: 70px;
  font-size: 35px;
}

.my-content {
  background: var(--el-color-danger-light-9);
  text-align: center;
}

/deep/ .el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-right {
  text-align: center;
}

img {
  border: 3px #bfc9d5 solid;
  margin: 0 auto;
  width: 65%;
  flex: 1;
  /*background-size: 70vw 60vh;*/
}


.my-content {
  background: var(--el-color-danger-light-9);
  text-align: center;
}

/deep/ .el-descriptions__body .el-descriptions__table .el-descriptions__cell.is-right {
  text-align: center;
}

@media (max-width: 1000px) {
  .item {
    display: block;
  }

  .all p {
    font-size: 20px;
  }
  .box .item img{
    width: 100%;
  }
  .box .item div{
    width: 100%;
  }
}

</style>
