<template>
  <div class="grid-content bg-purple card" shadow="hover" >
    <div class="card-content">
      <el-icon style="font-size: 30px"><component :is="item.icon"></component>
      </el-icon>
      <p>{{ item.data }}</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "shakeBox",
  props:{
    item:{
      default:{
        icon: "Search", data: "基于深度学习的模型训练与数据生产"
      }
    }
  }
}
</script>

<style scoped>
.card {
  width: 300px;
  transition: transform 0.3s;
  border: 0;
  margin: 0;
  padding: 30px;
  /*padding-left: 30px;*/
  /*padding-right: 30px;*/
  margin-top: 30px;
}
.card:hover {
  background-color: #00000000;
  transform: translateY(-10px);
  border: 0;
  box-shadow: #2c3e50;
  background-image: url("https://deepglint.com/img/jishu-item-bg.e2914f35.png");
  background-size: cover;
  background-position: center;
}
.card-content {
  padding: 20px;
}
</style>
