import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/views/Home";
import company from "@/views/company";
import index from "@/views/index";
import news from "@/views/news";
import joinUs from "@/views/joinUs";
import product from "@/views/product";
import technology from "@/views/technology";
import contact from "@/components/company/contact";


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children:[
      {
        path: '/',
        component: index
      },
      {
        path:'/index',
        component:index,
      },
      {
        path: '/company',
        name: 'company',
        component: company
      },
      {
        path: '/news',
        name:'news',
        component: news
      },
      {
        path: '/joinUs',
        component: joinUs
      },
      {
        path: '/product',
        name: 'product',
        component: product
      },
      {
        path: '/technology',
        component:technology
      },
      {
        path: '/contact',
        name: 'contact',
        component: contact
      }
    ]
  },
]

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
