<template>
  <div>
    <el-affix position="top" :offset="0">
      <Header :header="header"></Header>
    </el-affix>

    <router-view></router-view>

    <Footer :footer="footer"></Footer>
  </div>


</template>

<script>
import Header from "@/components/header";
import Index from "@/views/index";
import Footer from "@/components/footer";
import Company from "@/views/company";
import {header} from '@/data/header';
import {footer} from '@/data/footer';

export default {
  name: "Home",
  components: {Company, Footer, Index, Header},
  metaInfo:{
    title:'主页',
    meta:[
      {
        name:'关键词：主页',
        content:'主页啊'
      }
    ]
  },
  data(){
    return{
      header:header,
      footer:footer
    }
  }

}
</script>

<style scoped>

</style>
