<template>
  <div class="main">
      <p>{{companyShow.mani_title}}</p>
    <el-tabs stretch=true v-model="companyShow.activeName" @tab-click="handleClick" >
      <el-tab-pane :label="companyShow.title1" name="first" style="font-size: 30px">
        <div class="sub">
          <div class="demo-image__preview">
            <el-image class="img"
                :src="companyShow.backgroundImg[0][0]"
                :preview-src-list="companyShow.backgroundImg[0]">
            </el-image>
          </div>
          <div class="demo-image__preview">
            <el-image class="img"
                :src="companyShow.backgroundImg[1][0]"
                :preview-src-list="companyShow.backgroundImg[1]">
            </el-image>
          </div>
          <div class="demo-image__preview">
            <el-image class="img"
                      :src="companyShow.backgroundImg[2][0]"
                      :preview-src-list="companyShow.backgroundImg[2]">
            </el-image>
          </div>
        </div>
        <div class="sub">

          <div class="demo-image__preview">
            <el-image class="img"
                :src="companyShow.backgroundImg[3][0]"
                :preview-src-list="companyShow.backgroundImg[3]">
            </el-image>
          </div>
          <div class="demo-image__preview">
            <el-image class="img"
                      :src="companyShow.backgroundImg[4][0]"
                      :preview-src-list="companyShow.backgroundImg[4]">
            </el-image>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="companyShow.title2" name="second">

        <div class="sub">
          <div class="demo-image__preview">
            <el-image class="img"
                :src="companyShow.backgroundImg[5][0]"
                :preview-src-list="companyShow.backgroundImg[5]">
            </el-image>
          </div>
          <div class="demo-image__preview">
            <el-image class="img"
                :src="companyShow.backgroundImg[6][0]"
                :preview-src-list="companyShow.backgroundImg[6]">
            </el-image>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
export default {
  name: "companyShow",
  data(){
    return{

    }
  },
  props:{
    companyShow:{
      default:{

      }
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.main{
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 70px;
}
.main p{
  font-size: 40px;
}

.sub-first div{
  background-size: cover;
}
.sub{
  width: 100%;
  display: flex;

  flex-wrap: wrap;

}
.sub div{
  flex: 0.33;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.img{
  width: 90%;
  height: 90%;
}
.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
::v-deep .el-tabs__nav-scroll{
  width: 60%!important;
  margin: 0 auto!important;
}
/deep/ .el-tabs__item {
  font-size: 20px !important;

}
@media (max-width: 1000px) {
  .main p{
    font-size: 20px;
  }
    .sub{
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
    .sub div{
      width: 100%;
    }
  /deep/ .el-tabs__item {
    font-size: 15px !important;

  }
}
</style>
