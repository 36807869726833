<template>
  <div class="main" :style="{backgroundImage: 'url(' + companyHonor.backgroundImg + ')' }">
  <div class="block">
    <div class="title">{{companyHonor.title}}</div>
    <el-timeline>
      <el-timeline-item
          v-for="(activity, index) in companyHonor.activities"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :color="activity.color"
          :size="activity.size"
          :timestamp="activity.timestamp"
          >
        <p>{{activity.content}}</p>

      </el-timeline-item>
    </el-timeline>
  </div>
  </div>
</template>

<script>
export default {
  name: "companyHonors",
  props:{
    companyHonor:{
      default:{

      }
    }
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100%;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 60px;
}
.title{
  font-size: 40px;
  margin-bottom: 40px;
  padding-bottom: 60px;
}
.block{
  width: 60%;
  height: 80%;
  padding-bottom: 50px;
  margin: 0 auto;
}
.block div{
  color: #fefeff;
  padding-top: 30px;
}
.block p{
  font-size: 18px;
  color: #f6f6f8;
  margin: 0 auto;
}
/deep/.el-timeline-item__content{
  text-align: left;
}
/deep/.el-timeline-item__timestamp.is-bottom{
  text-align: left;
}
@media (max-width: 1000px) {

  .title{
    font-size:30px;
  }
  .block p{
    font-size: 13px;
  }
}
</style>
