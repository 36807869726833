<template>
  <div id="main" @mousewheel="scrollInit">

  <div id="index-bac-img" style="height: 100vh">
    <index-bac-img class="index"  style="height: 100vh" :viewData="indexTech"></index-bac-img></div>
  <div id="technology1" style="height: 100vh;background-color:  rgb(245,247,248)">
    <technology1 :technology1="technology1"></technology1>
  </div>
  <div id="technology2" style="height: 100vh">
    <technology2 :technology2="technology2"></technology2>
  </div>
<div id="tec1" style="height: 100vh">
  <div class="line"><el-divider border-style="double" /></div>
  <second  :title="title1" describe="" :type="techType1"></second>

</div>
    <div id="tec2" style="height: 100vh">
    <div class="line"><el-divider border-style="double" /></div>

    <second   :title="title2" describe="" :type="techType2"></second>
    </div>
    <el-backtop :right="100" :bottom="100" style="width: 100px;height: 100px" @click="fun">
      <template #default>回到顶部</template>
    </el-backtop>
  </div>
</template>

<script>
import Technology2 from "@/components/technology/technology2";
import Technology1 from "@/components/technology/technology1";
import {viewData,technology1,technology2,techType1,techType2} from "@/data/technology";
import Second from "@/components/index/second";
import IndexBacImg from "@/components/components/indexBacImg";

import {enableMove, scrollUtils, stopMove} from "@/utils/utils";
export default {
  name: "technology",
  components: {IndexBacImg, Second, Technology1,  Technology2},
  metaInfo:{
    title:'高量技术',
    meta:[
      {
        name:'关键词：高量技术',
        content:'高高量技术啊'
      }
    ]
  },
  data(){
    return{
      indexTech:viewData,
      technology1:technology1,
      technology2:technology2,
      techType1:techType1,
      techType2:techType2,
      title1:"系统功能",
      title2:"系统亮点",
      domList: [],
      nowIndex: 0,
      scrollStatus:{
        isScrolling: false

      }
    }
  },
  methods: {
    scrollInit(e) {
      stopMove()

      this.nowIndex = scrollUtils(e, this.nowIndex, this.domList,this.scrollStatus)
      console.log(this.scrollStatus)
    },
    fun(){
      this.nowIndex=0
    }

  },
  mounted() {
    stopMove()
    this.domList = Array.from(document.getElementById('main').children);
  },
}
</script>

<style scoped>
</style>
