<template>
<div class="main">
  <p>{{technology1.title}}</p>
  <img :src="technology1.backgroundImg">
</div>
</template>

<script>
export default {
  name: "technology1",
  props:{
    technology1:{
      default:{
      }
    }

  }

}
</script>

<style scoped>
.main{
  padding-bottom: 60px;

  width: 100%;
  margin: 0 auto;
  margin-top: -15px;
  text-align: center;
  padding-top: 15px;
  margin-bottom:20px;
}
p{
  font-size: 35px;
}
img{
  border: 2px #b1c4de solid;
  width:67vw;
  height: 67vh;
  box-shadow: 0 0 10px 1px skyblue;
}

@media (max-width: 768px) {
  p{
    font-size: 20px;
  }
}
</style>
