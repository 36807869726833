<template>
  <div id="main">
    <join-index :joinIndex="joinIndex"></join-index>
    <company-show :companyShow="companyShow"></company-show>
    <contact :contact="contact"></contact>
    <el-backtop :right="100" :bottom="100" style="width: 100px;height: 100px" @click="fun">
      <template #default>回到顶部</template>
    </el-backtop>
  </div>
</template>

<script>
import JoinIndex from "@/components/joinUs/joinIndex";
import CompanyShow from "@/components/joinUs/companyShow";
import Contact from "@/components/company/contact";
import {joinIndex,companyShow,contact} from '@/data/joinUs';
import {enableMove, scrollUtils, stopMove} from "@/utils/utils";

export default {
  name: "joinUs",
  components: {CompanyShow, JoinIndex,Contact},
  metaInfo:{
    title:'加入高量',
    meta:[
      {
        name:'关键词：加入高量',
        content:'加入高量啊'
      }
    ]
  },
  data(){
    return{
      joinIndex:joinIndex,
      companyShow:companyShow,
      contact:contact,
      domList: [],
      nowIndex: 0,
      scrollStatus:{
        isScrolling: false
      }
    }
  },
  methods: {
    scrollInit(e) {
      stopMove()
      this.nowIndex = scrollUtils(e, this.nowIndex, this.domList,this.scrollStatus)
    },
    fun(){
      this.nowIndex=0
    },
  },

}
</script>

<style scoped>

</style>
