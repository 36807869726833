import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//import MetaInfo from 'vue-meta-info'
//Vue.use(MetaInfo)
// Vue.use(VueMeta);

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// new Vue({
//     router,
//     store,
//     ElementPlus,
//
//     render: h => h(App),
//     mounted () {
//         document.dispatchEvent(new Event('render-event'))
//     }
// }).$mount('#app')
app.use(store).use(router).use(ElementPlus).mount('#app')

