<template>
<div>
  <index-bac-img  :viewData="productIndex" style="text-indent:-1.5em;height: 60vh"></index-bac-img>
  <Product1 :product1="product1"></Product1>
  <Product3 :product3="product3"></Product3>
  <product-show :productShow="productShow"></product-show>
  <el-backtop :right="100" :bottom="100" style="width: 100px;height: 100px" @click="fun">
    <template #default>回到顶部</template>
  </el-backtop>
</div>
</template>

<script>

import Product1 from "@/components/product/product1";
import Product2 from "@/components/technology/technology2";
import Product3 from "@/components/product/product3";
import ProductShow from "@/components/product/productShow";
import IndexBacImg from "@/components/components/indexBacImg";
import {productIndex,product1,product3,productShow} from "@/data/product";
import {enableMove, scrollUtils, stopMove} from "@/utils/utils";

export default {
  name: "Product",
  components: {IndexBacImg, ProductShow, Product3, Product2, Product1},
  metaInfo:{
    title:'高量产品',
    meta:[
      {
        name:'关键词：高量产品',
        content:'高量产品啊'
      }
    ]
  },
  data(){
    return{
      productIndex:productIndex,
      product1:product1,
      product3:product3,
      productShow:productShow,
      domList: [],
      nowIndex: 0,
      scrollStatus:{
        isScrolling: false
      }
    }
  },
  methods: {
    scrollInit(e) {
      stopMove()
      this.nowIndex = scrollUtils(e, this.nowIndex, this.domList,this.scrollStatus)
    },
    fun(){
      this.nowIndex=0
    }
  },

}
</script>

<style scoped>

</style>
