export const productIndex={
    icon: "Opportunity",
    title:["全自主平台级智能化高精密喷制装备"],
    describe:["\t高量科技已推出PCB阻焊/字符/识别码喷制机、多喷头自动清洗机、智能供墨系统等系列产品\n",
        "\t基于完全自研的全套软硬件架构，产品具备快速迭代、持续优化的能力，可快速适配各种工业场景。同时，可为各种新兴行业和应用场景，提供喷制装备的快速定制\n",],
    backgroundImg:require('../../public/img/productIndex.jpg'),
    text_align:'',
}

export const product1={
    title:"平台级可扩展型高精密喷制装备",
    items1:[
        {title:"喷头数量",content:"可扩展至9999个"},
        {title:"喷制精度",content:"最高精度达微米级"},
        {title:"喷制速度",content:"120米/分钟不限宽幅"},
        {title:"喷制厚度",content:"不同区域选择性厚度喷制"},
        {title:"喷制宽幅",content:"可根据客户生产线调整"},
        {title:"工艺环节",content:"可根据需求柔性增加"},
        {title:"云智能",content:"设备本身具备云智能"},
        {title:"核心软件",content:"自研数据模型融合型工业软件"},
    ],
    backgroundImg1:require('../../public/img/product1.png'),
}

export const product3={
    backgroundImg2: require('../../public/img/product3.png'),
    title2: "从多芯片到SoC，从分散式组件到高性能、高算力一体化集成装备\n",
    items2: [
        {title: "支持喷头数\n", content: "理论值>10000个@1200dpi\n"},
        {title: "喷头同步方式\n", content: "控制信号同步\n"},
        {title: "喷头同步延迟\n", content: "<15us高速稳定运行\n"},
        {title: "打印速度\n", content: ">200m/min@1200dpi,不随喷头增加而变化\n"},
        {title: "RIP算法\n", content: "AI增强算法大幅提升图像处理精度\n"},
        {title: "RIP计算方式\n", content: "分布式\n"}
    ],
}

export const productShow={
    title:"产品展示",
    product:[
        {name:"PCB高精密喷制装备",backgroundImg:require('../../public/img/device1.jpg'),describe:"暂无"},
        {name:"多功能喷头清洗设备",backgroundImg:require('../../public/img/device2.jpg'),describe:"暂无"}
    ]
}
