<template>
  <div style="background-color: #fdfeff">
    <p class="title">{{productShow.title}}</p>
    <div class="main">
    <el-card class="box-card" v-for="(item,index) in productShow.product" :key="index">
      <img class="imgBgc" :src="item.backgroundImg" >
      <h2>{{item.name}}</h2>
      <p>{{item.describe}}</p>
    </el-card>

  </div></div>

</template>

<script>
export default {
  name: "productShow",
  props:{
    productShow:{
      default:{

      }
    }
  }
}
</script>

<style scoped>
.main{
  width: 80vw;
  height: auto;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.title{
  font-size: 35px;
}
.box-card {
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px; /* 子元素之间的间距 */
  padding: 10px; /* 子元素内部的间距 */
  border: 1px solid #dcdcdc; /* 子元素边框 */
  border-radius: 10px; /* 子元素圆角 */
  transition: all 0.6s;/*所有属性变化在0.6秒内执行动画*/
}
.box-card:hover{
  transform: scale(1.2);/*鼠标放上之后元素变成1.4倍大小*/
}
.imgBgc{
  height: 40vh;
}
.box-card h2{
  text-align: left;
}
.box-card p{
  text-align: left;
}
/deep/.el-card__body{
  padding: 0;
}

@media (max-width: 768px) {
  .box-card {
    width: 80vw;
  }
  .box-card h2 {
    font-size: 20px;
  }
}
</style>
