export const joinIndex={
    backgroundImg:require('../../public/img/joinUs1.jpg'),
    titles:[
        "职业梦想，起于高量；",
        "在这里，你将遇见许多优秀的伙伴；",
        "在这里，你将拥有一个更加美好的未来！",
    ]
}

export const companyShow={
    mani_title:'高量生活',
    title1:"高量环境",
    title2:"办公环境",

    backgroundImg:[
        [require('../../public/img/address2.jpg')],
        [require('../../public/img/companyShow1.jpg')],
        [require('../../public/img/companyShow2.jpg')],
        [require('../../public/img/companyShow3.jpg')],
        [require('../../public/img/companyShow4.jpg')],

        [require('../../public/img/companyShow5.jpg')],
        [require('../../public/img/companyShow6.jpg')],
    ],
    activeName: 'first'
}

export const contact={
    title:"诚聘英才",
    icon:"LocationInformation",
    address:"无锡市滨湖区科教园一期惠泽路与锦溪路交叉口西南侧A楼608室",
    contactWay:[
        {icon:"PhoneFilled",title:"联系电话:",way:"0510-65589690"},
        {icon:"Message",title:"联系邮箱:",way:"17851358216@163.com"},

    ],

    QRcode:[
        {type:"微信",QRcode:""},
        {type:"微博",QRcode:""}
    ]
}
