<template>
<div class="main">
  <div class="img" :style="{backgroundImage: 'url(' + newsIndex.backgroundImg + ')' }">
    <p>{{newsIndex.title}}</p>
    <span>{{newsIndex.subTitle}}</span>
  </div>
</div>
</template>

<script>


export default {
  name: "newsIndex",
  props:{
    newsIndex:{
      default:{

      }
    }
  }
}
</script>

<style scoped>
.main{
  height: 80vh;
  width: 100%;
}
.img{
  height: 100%;
}
.img p{
  font-size: 45px;
  color: white;
  margin: auto;
  padding-top: 20vh;
  padding-bottom: 5px;
}
.img span{
  color: #e7e5e5;
  font-size: 20px;
}
</style>
