<template>
  <div class="first_img" :style="{backgroundImage: 'url(' + viewData.backgroundImg + ')' }">
    <div v-for="(item,index) in viewData.title" class="title1">
      <el-icon>
        <component :is="viewData.titleicon"></component>
      </el-icon>
      {{ item }}
    </div>
    <p v-for="(item,index) in viewData.describe" :key="index" :style="viewData.text_align" class="body_p">
      <el-icon>
        <component :is="viewData.icon"></component>
      </el-icon>
      {{ item }}
    </p>
  </div>
</template>

<script>
export default {
  name: "indexBacImg",
  props: {
    viewData: {
      default: {
        title: [],
        backgroundImg: '',
        describe: [],
        titleicon: '',
        icon: '',
        text_align: ''
      }
    }

  }
}
</script>

<style scoped>

.first_img {
  width: 100%;
  background-size: cover;
  text-align: left;
}

.first_img div:first-child {
  padding-top: 10%;

}

.first_img div {
  width: 85vw;
  padding-bottom: 50px;
  margin: 0 auto;
  font-size: 30px;
  color: white;
}

.title1 {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 10px;
}

.body_p {
  font-size: 20px;
  line-height: 30px;
  margin: 0 auto;
  letter-spacing: 4px;
}

.first_img p {
  font-size: 20px;
  width: 85vw;
  color: #e5e9f2;
  line-height: 42px;
}

.first_img p:last-child {
  padding-bottom: 10%;
}

@media (max-width: 768px) {
  .first_img div {
    font-size: 18px;
  }

  .first_img p {
    font-size: 10px;
  }
}
</style>
