export const viewData={
    icon: "Star",
    titleicon:'Opportunity',
    title:["\t高量科技将人工智能、高性能计算、物联网、高精密运控等前沿技术融为一体，打造了适合工业大规模应用、具备数据智能的高精密喷材制造装备","\t高量科技的核心关键技术包括："],
    describe:["自主可控AI增强RIP工业软件技术\n",
        "喷材制造领域专用处理器技术\n",
        "\t喷材制造专用高性能计算技术\n",
        "\t基于分布式互联的云边协同与智能工业云技术\n"],
    backgroundImg:require('../../public/img/technologyIndex.jpg'),
    text_align:'text-align:left',
}

export const technology1={
    title:"工业超级云生态",
    backgroundImg:require('../../public/img/technology1.png'),
}
export const technology2={
    title1:"云边协同一站式工业喷墨生态习系统",
    backgroundImg:require('../../public/img/technology2.png'),
}
export const
    techType1= [
        {icon: "Platform", data: "人工智能深度学习"},
        {icon: "HelpFilled", data: "产品个性化、身份化"},
        {icon: "Share", data: "区块链数据共享机制"},
        {icon: "Histogram", data: "产品精准溯源\n"},
        {icon: "StarFilled", data: "产品智能定级\n"},
        {icon: "SuccessFilled", data: "产品IP保护\n"},
    ]
export const techType2 = [
    {icon: "Menu", data: "模块化"},
    {icon: "Checked", data: "低成本"},
    {icon: "TrendCharts", data: "部署快"},
    {icon: "Platform", data: "易运维"}
]
